import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Helmet from "react-helmet";
import { MarkdownLayout } from '../../partials/MarkdownLayout';
import { Colors, Fonts, MaxWidths } from "../../materials";
import { ImageStaticFluid, Container, Label, LinkGatsby, Heading, LinkArrowGatsby, Box, LinkAnchor, Code, ButtonIcon, Input, List, ListItem, Note, Text, TextBold } from "../../elements";
import { SnippetBreadcrumbs } from '../../partials/SnippetBreadcrumbs';
import { SnippetInputWithoutLabel } from '../../partials/SnippetInputWithoutLabel';
import { SnippetNavigationLandmarksAriaLabel } from '../../partials/SnippetNavigationLandmarksAriaLabel';
import { SnippetRegionLandmarks } from '../../partials/SnippetRegionLandmarks';
export const _frontmatter = {
  "seoTitle": "aria-current - examples and best practices | Aditus",
  "seoURL": "https://www.aditus.io/aria/aria-current/",
  "seoImage": "https://www.aditus.io/social/aria/aria-current.png",
  "seoImageAlt": "Text reading 'aria-current' with the Aditus logo next to it",
  "title": "aria-current",
  "description": "The aria-current attribute should be used to identify the current item in a set of items. See its applications and learn how to use to make your website more accessible.",
  "lead": ["The <code>aria-current</code> attribute should be used to identify the current item in a set of items. Below we show some applications of this attribute to make websites more accessible to all users.\n"],
  "toc": {
    "nodes": [{
      "selfId": "definition",
      "selfText": "Definition"
    }, {
      "selfId": "when-and-how-to-use",
      "selfText": "When and how to use",
      "nodes": [{
        "selfId": "aria-current-page",
        "selfText": "aria-current=\"page\""
      }, {
        "selfId": "aria-current-location",
        "selfText": "aria-current=\"location\""
      }, {
        "selfId": "aria-current-date",
        "selfText": "aria-current=\"date\""
      }, {
        "selfId": "aria-current-step",
        "selfText": "aria-current=\"step\""
      }]
    }, {
      "selfId": "examples",
      "selfText": "Examples",
      "nodes": [{
        "selfId": "example-1-breadcrumbs",
        "selfText": "Breadcrumbs"
      }]
    }, {
      "selfId": "aria-current-vs-aria-selected",
      "selfText": "vs. aria-selected",
      "nodes": [{
        "selfId": "tabs-widget",
        "selfText": "Tabs"
      }, {
        "selfId": "date-picker-widget",
        "selfText": "Date Picker"
      }]
    }]
  }
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <MarkdownLayout {...props} renderAfter={() => <>
      <nav aria-labelledby='related-topics'>
        <Box mt={6} />
        <Box bg={Colors.oc.gray[0]} py={8}>
          <Container maxWidth='l'>
            <Heading>Related Topics</Heading>
            <Box mt={6} />
            <List display='flex' m={-4}>
              <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
                <LinkGatsby to='/aria/aria-label/' css={{
              display: 'block',
              textDecoration: 'none',
              textAlign: 'left',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[1],
                border: `5px solid ${Colors.oc.blue[9]}`
              }
            }}>
                  <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                    <Box display='flex' flexDirection='column'>
                      <Label as='span'>ARIA</Label>
                      <Text as='code' fontSize={5} css={{
                    fontFamily: Fonts.mono.family,
                    fontWeight: Fonts.mono.weight,
                    textDecoration: 'underline'
                  }}>
                        aria-label
                      </Text>
                    </Box>
                  </Box>
                </LinkGatsby>
              </ListItem>
              <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
                <LinkGatsby to='/patterns/breadcrumbs/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                  <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                    <Box display='flex' flexDirection='column'>
                      <Label as='span'>Patterns</Label>
                      <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                        Breadcrumbs
                      </Text>
                    </Box>
                  </Box>
                </LinkGatsby>
              </ListItem>
              <ListItem p={4} width={[1, 1 / 2, 1 / 3]}>
                <LinkGatsby to='/patterns/multiple-navigation-landmarks/' css={{
              display: 'block',
              textDecoration: 'none',
              '& > *': {
                backgroundColor: Colors.oc.gray[2],
                border: `5px solid ${Colors.oc.gray[3]}`
              },
              '&:hover > *': {
                backgroundColor: Colors.oc.blue[0],
                border: `5px solid ${Colors.oc.blue[3]}`
              }
            }}>
                  <Box display='flex' alignItems='center' justifyContent='center' px={3} py={3} height={180}>
                    <Box display='flex' flexDirection='column'>
                      <Label as='span'>Patterns</Label>
                      <Text as='span' fontSize={5} css={{
                    textDecoration: 'underline'
                  }}>
                        Multiple <code>{`<nav>`}</code>
                      </Text>
                    </Box>
                  </Box>
                </LinkGatsby>
              </ListItem>
            </List>
          </Container>
        </Box> 
      </nav>
    </>} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Definition`}</h2>
    <Box mt={4} mdxType="Box" />
    <p><inlineCode parentName="p">{`aria-current`}</inlineCode>{` is an attribute defined in the `}<LinkAnchor href='https://www.w3.org/TR/wai-aria-1.1/#aria-current' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">{`WAI-ARIA`}</LinkAnchor>{`
specification. This specification extends native HTML, allowing you
to change the way an HTML element is "translated" into the accessibility tree.`}</p>
    <Box mt={6} mdxType="Box" />
    <p>{`The `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{` can be used to indicate that an element represents the current
item within  container or set of related elements. It can take multiple values, specifically:
`}<inlineCode parentName="p">{`page`}</inlineCode>{`, `}<inlineCode parentName="p">{`step`}</inlineCode>{`, `}<inlineCode parentName="p">{`location`}</inlineCode>{`, `}<inlineCode parentName="p">{`date`}</inlineCode>{`, `}<inlineCode parentName="p">{`time`}</inlineCode>{`, `}<inlineCode parentName="p">{`false`}</inlineCode>{`, `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h2>{`When and how to use`}</h2>
    <h3><inlineCode parentName="h3">{`aria-current="page"`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`Whenever you need to indicate the current page within a set of pagination links (main navigation),
providing a screen reader alternative to styling the current page.`}</p>
    <Box mt={7} mdxType="Box" />
    <Box mdxType="Box">
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-page.png' alt='Screenshot of polaris.shopify.com' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={4} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text"><LinkAnchor href='https://polaris.shopify.com/design/colors' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Spotify Polaris</LinkAnchor> indicates the current page in the main navigation. While visible to
                  sighted users, it also uses <code>{`aria-current="page"`}</code> to convey the information
                  to screen reader users.</Text>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Note title='How VoiceOver announces it' mdxType="Note">
  <Box pt={4} mdxType="Box" />
  <Box border={`1px solid ${Colors.oc.gray[2]}`} minWidth={['100%', 670]} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-page-vo.png' alt='Screenshot of voice over announcing aria-current="page"' mdxType="ImageStaticFluid" />
  </Box>
    </Note>
    <Box mt={8} mdxType="Box" />
    <h3><inlineCode parentName="h3">{`aria-current="location"`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`Whenever a website hierarchy is deeply nested, it's often a good practice to
display the hierarchy of a given page using the `}<LinkGatsby to='/patterns/breadcrumbs/' mdxType="LinkGatsby">{`Breadcrumbs Pattern`}</LinkGatsby>{`.
By using `}<inlineCode parentName="p">{`aria-current="location"`}</inlineCode>{` we can mark the current page in the hierarchy to screen reader users.`}</p>
    <Box mt={7} mdxType="Box" />
    <Box mdxType="Box">
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-location.png' alt='Screenshot of service-public.fr' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={4} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text"><LinkAnchor href='https://www.service-public.fr/particuliers/vosdroits/N23493' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Service-Public.fr</LinkAnchor> website uses the <LinkGatsby to='/patterns/breadcrumbs/' mdxType="LinkGatsby">Breadcrumbs Pattern</LinkGatsby> to display
                  the hierarchy of the current page.</Text>
    </Box>
    <Box mt={4} mdxType="Box" />
    <Note title='How VoiceOver announces it' mdxType="Note">
  <Box pt={4} mdxType="Box" />
  <Box border={`1px solid ${Colors.oc.gray[2]}`} minWidth={['100%', 670]} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-location-vo.png' alt='Screenshot of voice over announcing aria-current="location"' mdxType="ImageStaticFluid" />
  </Box>
    </Note>
    <Box mt={8} mdxType="Box" />
    <h3><inlineCode parentName="h3">{`aria-current="date"`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`When we display a list of dates, one being today's date, we should use the \`aria-current="date"
to mark the current date to screen reader users.`}</p>
    <Box mt={7} mdxType="Box" />
    <Box mdxType="Box">
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-date.png' alt='Screenshot of gov.uk' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={4} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text"><LinkAnchor href='https://www.delta.com' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Delta.com</LinkAnchor> uses <code>{'aria-current="page"'}</code> to identify the current
                  date to screen reader users.</Text>
    </Box>
    <Note title='Note' mdxType="Note">
  <Text mdxType="Text">
    <code>{`aria-current="date"`}</code> and <code>{`aria-current="time"`}</code>
    are very similar. The difference is that the former should be used for days and the latter
    for times.
  </Text>
    </Note>
    <Box mt={8} mdxType="Box" />
    <h3><inlineCode parentName="h3">{`aria-current="step"`}</inlineCode></h3>
    <Box mt={4} mdxType="Box" />
    <p>{`If we need to indicate the current step within a step indicator of a multi-step process (e.g.: wizard
form, multi-step checkout, etc), `}<inlineCode parentName="p">{`aria-current="step"`}</inlineCode>{` should be used to indicate the
current step to screen reader users.`}</p>
    <Box mt={7} mdxType="Box" />
    <Box mdxType="Box">
  <Box border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/aria-current-step.png' alt='Screenshot of swiss.com' mdxType="ImageStaticFluid" />
  </Box>
  <Box mt={4} mdxType="Box" />
  <Text fontSize={2} color={Colors.oc.gray[7]} mdxType="Text"><LinkAnchor href='https://www.swiss.com' target="_blank" rel="noopener noreferrer" mdxType="LinkAnchor">Swiss.com</LinkAnchor>'s process of buying a ticket is a multi-step process.
                  Visually, the current step is indicated to sighted users. <code>{`aria-current="step"`}</code> should be used
                  as an alternative to screen reader users.</Text>
    </Box>
    <Box mt={10} mdxType="Box" />
    <h2>{`Examples`}</h2>
    <h3>{`Example 1: Breadcrumbs`}</h3>
    <Box mt={4} mdxType="Box" />
    <SnippetBreadcrumbs mdxType="SnippetBreadcrumbs" />
    <Box mt={6} mdxType="Box" />
    <h2><inlineCode parentName="h2">{`aria-current`}</inlineCode>{` vs. `}<inlineCode parentName="h2">{`aria-selected`}</inlineCode></h2>
    <h3>{`Tabs Widget`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`In widgets where `}<inlineCode parentName="p">{`aria-selected`}</inlineCode>{` has the same meaning
as `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{`, we should use `}<inlineCode parentName="p">{`aria-selected`}</inlineCode>{` instead.`}</p>
    <Box mt={4} mdxType="Box" />
    <p>{`For example, when using `}<strong parentName="p">{`Tabs`}</strong>{`, we should mark the
currently selected tab with `}<inlineCode parentName="p">{`aria-selected="true`}</inlineCode>{`. This has the same meaning as
`}<inlineCode parentName="p">{`aria-current="page"`}</inlineCode>{`, but `}<inlineCode parentName="p">{`aria-selected="true"`}</inlineCode>{` is preferred.`}</p>
    <Box mt={6} mdxType="Box" />
    <Code codeString={` <div role="tablist" aria-label="Entertainment">
  <button id="tab-1-button" role="tab"
          aria-controls="tab-1" aria-selected="true">
    Tab 1
  </button>
  <button id="tab-2-button" role="tab"
          aria-controls="tab-2"
  >
    Tab 2
  </button>
</div>
<div id="tab-1" role="tabpanel"
     tabindex="0" aria-labelledby="tab-1-button"
>
  ...
</div>
<div id="tab-2" role="tabpanel"
     tabindex="0" aria-labelledby="tab-2-button"
      hidden
>
  ...
</div>
`} language='html' mdxType="Code" />
    <h3>{`Date Picker Widget`}</h3>
    <Box mt={4} mdxType="Box" />
    <p>{`Another place where it can be confusing to use either `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-selected`}</inlineCode>{` is on a date picker.
The former should be used to signal today's date and the former should be used to signal the
user's selection.`}</p>
    <Box display='flex' justifyContent='center' mdxType="Box">
  <Box width={['100%', '100%', '100%', 500]} border={`1px solid ${Colors.oc.gray[2]}`} mdxType="Box">
    <ImageStaticFluid path='articles/aria/aria-current/vs-aria-current.png' alt='Screenshot of swiss.com' mdxType="ImageStaticFluid" />
  </Box>
    </Box>  


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      